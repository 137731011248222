import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Header, Footer } from './Widgets';
import { UserContext } from '../context/UserContext';

export function Home() {
    const [auth, setAuth] = useState(false);
    const [message, setMessage] = useState('');
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const navigate = useNavigate();
    const { setUserInfo, userInfo } = useContext(UserContext);
    const [adminData, setAdminData] = useState([]);

    axios.defaults.withCredentials = true;

    useEffect(() => {
        axios.get('https://app-techcomp-16ff4d30c364.herokuapp.com')
            .then(res => {
                if (res.data.Status === "Exito") {
                    setAuth(true);
                    setUserInfo({ name: res.data.name, type: res.data.type });
                } else {
                    setAuth(false);
                    setMessage(res.data.Error);
                    navigate('/login');
                }
            })
            .catch(err => {
                console.log(err);
                setMessage("Error al registrar");
            });
    }, [navigate, setUserInfo]);

    useEffect(() => {
        const htmlElement = document.documentElement;
        if (menuCollapsed) {
            htmlElement.classList.add('layout-menu-collapsed');
        } else {
            htmlElement.classList.remove('layout-menu-collapsed');
        }
    }, [menuCollapsed]);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
        const htmlElement = document.documentElement;
        const layoutMenu = document.getElementById('layout-menu');
        if (!menuCollapsed) {
            layoutMenu.classList.add('slide-in');
            layoutMenu.classList.remove('slide-out');
            htmlElement.classList.add('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-menu-expanded');
        } else {
            layoutMenu.classList.add('slide-out');
            layoutMenu.classList.remove('slide-in');
            htmlElement.classList.remove('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-menu-expanded');
        }
    };

    useEffect(() => {
        if (userInfo && userInfo.type === 'admin') {
            axios.get('https://app-techcomp-16ff4d30c364.herokuapp.com/admin/reports', { withCredentials: true })
                .then(res => {
                    if (res.data.Status === 'Exito') {
                        setAdminData(res.data.Data);
                    }
                }).catch(err => console.log(err));
        }
    }, [userInfo]);

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
                    <div className="app-brand demo" style={{ cursor: 'pointer' }}>
                        <a href="#" className="app-brand-link">
                            <span className="app-brand-logo demo">
                                <img src="/assets/img/favicon/favicon-techcomp.png" style={{ width: '25px' }} />
                            </span>
                            <span className="app-brand-text demo menu-text fw-semibold ms-2">Techcomp</span>
                        </a>
                    </div>

                    <div className="menu-inner-shadow"></div>

                    <ul className="menu-inner py-1">
                        <li className="menu-item active open">
                            <a href='/' className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ri-home-smile-line"></i>
                                <div data-i18n="Dashboards">Dashboard</div>
                            </a>
                        </li>
                        <li className="menu-item active open">
                            <a href='/report' className="menu-link menu-toggle">
                                <i className="menu-icon ri-file-search-line"></i>
                                <div data-i18n="Dashboards">Reportes</div>
                            </a>
                        </li>
                    </ul>
                </aside>
                <div className={`layout-page ${menuCollapsed ? 'layout-menu-collapsed' : ''}`}>
                    <Header toggleMenu={handleToggleMenu}/>
                    {auth ? (
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                {userInfo && userInfo.type === 'admin' ? (
                                    <div className="card">
                                        <div className="card-datatable table-responsive">
                                            <table className="datatables-users table">
                                                <thead>
                                                    <tr>
                                                        <th>Usuario</th>
                                                        <th>Tipo</th>
                                                        <th>Región</th>
                                                        <th>Folio</th>
                                                        <th>Fecha</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {adminData.map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="sorting_1">
                                                                <div className="d-flex justify-content-start align-items-center user-name">
                                                                    <div className="avatar-wrapper">
                                                                        <div className="avatar avatar-sm me-3">
                                                                            <img src="/assets/img/avatars/1.png" className="rounded-circle" alt="avatar" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <a href="" className="text-truncate text-heading">
                                                                            <span className="fw-medium">{data.name}</span>
                                                                        </a>
                                                                        <small>{data.email}</small>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><span>{data.type}</span></td>
                                                            <td><span>México</span></td>
                                                            <td><a href={`/report/${data.sku}`}><span>{data.sku}</span></a></td>
                                                            <td><span>{new Date(data.upload_date).toLocaleString()}</span></td>
                                                            <td>
                                                                <div className="d-flex align-items-center gap-50">
                                                                    <a href={`/report/${data.sku}`} className="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect" data-bs-toggle="tooltip" title="Preview">
                                                                        <i className="ri-eye-line ri-20px"></i>
                                                                    </a>
                                                                    <button className="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                                        <i className="ri-more-2-line ri-20px"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-end m-0">
                                                                        <a href={`/report/${data.sku}`} className="dropdown-item">
                                                                            <i className="ri-eye-line me-2"></i>
                                                                            <span>Ver</span>
                                                                        </a>
                                                                        <a href="#" className="dropdown-item">
                                                                            <i className="ri-edit-box-line me-2"></i>
                                                                            <span>Editar</span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : (
                                    <h3>Eres usuario</h3>
                                )}
                            </div>
                            <Footer />
                        </div>
                    ) : (
                        <div>
                            <h3>{message}</h3>
                            <h3>Iniciar sesión</h3>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Home;
