import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../context/UserContext';

export function Header({ toggleMenu }) {
    const { userInfo, setUserInfo } = useContext(UserContext); // Asegúrate de desestructurar setUserInfo
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if (userInfo) {
            setName(userInfo.name);
        }
    }, [userInfo]);

    axios.defaults.withCredentials = true;

    const handleLogout = () => {
        axios.get('https://app-techcomp-16ff4d30c364.herokuapp.com/logout', { withCredentials: true })
            .then(res => {
                if (res.data.Status === 'Exito') {
                    setUserInfo(null);  // Limpiar la información del usuario en el contexto
                    navigate('/login');  // Redirigir al usuario a la página de inicio de sesión
                }
            }).catch(err => console.log(err));
    }
    return (
        <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none" style={{ cursor: 'pointer' }} onClick={toggleMenu}>
                <a className="nav-item nav-link px-0 me-xl-6" href="#">
                    <i className="ri-menu-fill ri-22px"></i>
                </a>
            </div>
            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <ul className="navbar-nav flex-row align-items-center ms-auto">
                    <li className="nav-item navbar-dropdown dropdown-user dropdown">
                        <a className="nav-link dropdown-toggle hide-arrow" href="/" data-bs-toggle="dropdown">
                            <div className="avatar avatar-online">
                                <img src="../assets/img/avatars/1.png" className="rounded-circle" />
                            </div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <a className="dropdown-item" href="#">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar avatar-online">
                                                <img src="../assets/img/avatars/1.png" className="rounded-circle" />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="fw-medium d-block small">{userInfo?.name || 'Usuario'}</span>
                                            <small className="text-muted">{userInfo?.type || 'Tipo'}</small>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <div className="d-grid px-4 pt-2 pb-1">
                                    <button className="btn btn-sm btn-danger d-flex" onClick={handleLogout} target="_blank" style={{ color: 'white' }}>
                                        <small className="align-middle">Salir</small>
                                        <i className="ri-logout-box-r-line ms-2 ri-16px"></i>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="navbar-search-wrapper search-input-wrapper d-none">
                <input
                    type="text"
                    className="form-control search-input container-xxl border-0"
                    placeholder="Buscar..."
                    aria-label="Buscar..." />
                <i className="ri-close-fill search-toggler cursor-pointer"></i>
            </div>
        </nav>
    );
}

export function Footer() {
    return (
        <footer className="content-footer footer bg-footer-theme">
            <div className="container-xxl">
                <div
                className="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column">
                <div className="text-body mb-2 mb-md-0">
                    ©
                    <script>
                    document.write(new Date().getFullYear());
                    </script>
                    Desarrollada por <a href="https://rkcreativo.com.mx" target="_blank" className="footer-link">  Rocky Creativo  </a>
                </div>
                </div>
            </div>
        </footer>
    )
}

export function Widgets() {
    return (
      <div>
          
      </div>
    )
  }

export default Widgets