import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Header, Footer } from './Widgets';
import { UserContext } from '../context/UserContext';
import Swal from 'sweetalert2';
import Webcam from 'react-webcam';

export function Report() {
    const { sku } = useParams();
    const [message, setMessage] = useState('');
    const [report, setReport] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(null);
    const [showWebcam, setShowWebcam] = useState(false);
    const webcamRef = useRef(null);
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { setUserInfo, userInfo } = useContext(UserContext);

    useEffect(() => {
        fetchReportData();
    }, [sku]);

    const fetchReportData = () => {
        axios.get(`https://app-techcomp-16ff4d30c364.herokuapp.com/report/${sku}`, {
            withCredentials: false 
        })
            .then(res => {
                if (res.data.Status === 'Exito') {
                    setReport(res.data.Report);
                    if (res.data.Report.image_name) {
                        setPreview(`https://storage.googleapis.com/app-techcomp.appspot.com/${res.data.Report.image_name}`);
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Folio no encontrado',
                        text: 'Este modelo aun no se encuentra en nuestra base de datos',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                    }).then(() => {
                        navigate('/report');
                    });
                }
            })
            .catch(err => {
                setMessage('Error al verificar el Folio fetchReportData');
                console.log(err);
            });
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg'];
    
        if (selectedFile && !allowedExtensions.includes(selectedFile.type)) {
            Swal.fire({
                icon: 'error',
                title: 'Archivo no permitido',
                text: 'Solo se permiten archivos .png, .jpg y .jpeg',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            });
            setFile(null);
            setPreview(null);
            return;
        }
    
        setFile(selectedFile);
    
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
    
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    };

    const handleUpload = (e) => {
        e.preventDefault();
        if (!file) {
            Swal.fire({
                icon: 'error',
                title: 'Archivo requerido',
                text: 'Por favor seleccione un archivo antes de confirmar',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            });
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userInfo.id);

        axios.post(`https://app-techcomp-16ff4d30c364.herokuapp.com/report/upload/${sku}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            setTimeout(() => {
                setLoading(false);
                if (res.data.Status === 'Exito') {
                    setMessage('Archivo subido con éxito');
                    fetchReportData(); // Actualizar el estado del reporte después de subir el archivo
                    Swal.fire({
                        icon: 'success',
                        title: 'Éxito',
                        text: 'La imagen se ha subido con éxito',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                    });
                } else {
                    setMessage(res.data.Error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Hubo un error al subir la imagen',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                    });
                }
            }, 5000); // 5 segundos de retraso
        })
        .catch(err => {
            setTimeout(() => {
                setLoading(false);
                setMessage('Error al subir el archivo');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un error al subir la imagen',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                });
                console.log(err);
            }, 5000); // 5 segundos de retraso
        });
    };

    const handleCapture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        const imageFile = dataURLtoFile(imageSrc, 'captured_image.jpg');
        setFile(imageFile);
        setPreview(imageSrc);
        setShowWebcam(false);
    };

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handleLogout = () => {
        axios.get('https://app-techcomp-16ff4d30c364.herokuapp.com/logout')
        .then(res => {
            window.location.reload(true);
        }).catch(err => console.log(err));
    };


    const handleRemoveImage = () => {
        setFile(null);
        setPreview(null);
    };

    useEffect(() => {
        const htmlElement = document.documentElement;
        if (menuCollapsed) {
            htmlElement.classList.add('layout-menu-collapsed');
        } else {
            htmlElement.classList.remove('layout-menu-collapsed');
        }
    }, [menuCollapsed]);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
        const htmlElement = document.documentElement;
        const layoutMenu = document.getElementById('layout-menu');
        if (!menuCollapsed) {
            layoutMenu.classList.add('slide-in');
            layoutMenu.classList.remove('slide-out');
            htmlElement.classList.add('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-menu-expanded');
        } else {
            layoutMenu.classList.add('slide-out');
            layoutMenu.classList.remove('slide-in');
            htmlElement.classList.remove('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-menu-expanded');
        }
    };

    const handleNavigateToLogin = () => {
        navigate('/login', { state: { from: location } });
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
                    <div className="app-brand demo" style={{ cursor: 'pointer' }}>
                        <a href="#" className="app-brand-link">
                            <span className="app-brand-logo demo">
                                <img src="/assets/img/favicon/favicon-techcomp.png" alt="techcomp" style={{ width: '25px' }} />
                            </span>
                            <span className="app-brand-text demo menu-text fw-semibold ms-2">Techcomp</span>
                        </a>
                    </div>

                    <div className="menu-inner-shadow"></div>

                    <ul className="menu-inner py-1">
                        <li className="menu-item active open">
                            <a href='/' className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ri-home-smile-line"></i>
                                <div data-i18n="Dashboards">Dashboard</div>
                            </a>
                        </li>
                        <li className="menu-item active open">
                            <a href='/report' className="menu-link menu-toggle">
                                <i className="menu-icon ri-file-search-line"></i>
                                <div data-i18n="Dashboards">Reportes</div>
                            </a>
                        </li>
                    </ul>
                </aside>
                <div className="layout-page">
                    <Header toggleMenu={handleToggleMenu}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row g-6">
                                <div className="col-12 col-xxl-12">
                                    <div className="card h-100">
                                        <div className="card-header">
                                            <h5 className="mb-0 text-center">QR: {sku}</h5>
                                        </div>
                                        {report ? (
                                            <div className="card-body pt-4">
                                                {report.image_uploaded === 0 ? (
                                                    userInfo ? (
                                                        <form onSubmit={handleUpload} className="upload-form">
                                                            <div className="upload-container">
                                                                <label htmlFor="fileUpload" className="upload-label">
                                                                    {preview ? (
                                                                        <div className="upload-preview-container">
                                                                            <img src={preview} alt="Vista previa" className="upload-preview" />
                                                                            <button type="button" className="remove-preview" onClick={handleRemoveImage}>
                                                                                &times;
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            {showWebcam ? (
                                                                                <>
                                                                                    <Webcam
                                                                                        audio={false}
                                                                                        ref={webcamRef}
                                                                                        screenshotFormat="image/jpeg"
                                                                                        className="webcam"
                                                                                        videoConstraints={{ facingMode: 'environment' }}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <div className="upload-icon">
                                                                                    <i className="ri-file-upload-line" style={{ fontSize: '50px' }}></i>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    <span className="upload-text">
                                                                        {file ? file.name : "Cargar foto"}
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    id="fileUpload"
                                                                    className="upload-input"
                                                                    onChange={handleFileChange}
                                                                />
                                                            </div>
                                                            <div className="upload-buttons">
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-secondary waves-effect waves-light" 
                                                                    onClick={() => {
                                                                        setShowWebcam(!showWebcam);
                                                                        if (!showWebcam) {
                                                                            setPreview(null);
                                                                            setFile(null);
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="ri-camera-line" style={{ fontSize: '15px', marginRight: '2px' }}></i>
                                                                    {showWebcam ? 'Cerrar Cámara' : 'Abrir Cámara'}
                                                                </button>
                                                                {showWebcam && (
                                                                    <button type="button" className="btn btn-primary" onClick={handleCapture}>Tomar Foto</button>
                                                                )}
                                                                {(!showWebcam || preview) && (
                                                                    loading ? (
                                                                        <button className="btn btn-primary waves-effect waves-light" type="button" disabled>
                                                                            <span className="spinner-grow me-1" role="status" aria-hidden="true"></span>
                                                                            Cargando...
                                                                        </button>
                                                                    ) : (
                                                                        <button type="submit" className="btn btn-primary waves-effect waves-light">
                                                                            Subir Foto
                                                                        </button>
                                                                    )
                                                                )}
                                                            </div>
                                                        </form>
                                                    ) : (
                                                        <div className="text-center">
                                                            <form className="upload-form" onClick={handleNavigateToLogin}>
                                                                <div className="upload-container">
                                                                    <label htmlFor="fileUpload" className="upload-label">
                                                                        <div className="upload-icon">
                                                                            <i className="ri-file-warning-line" style={{ fontSize: '50px' }}></i>
                                                                        </div>
                                                                        <span className="upload-text" style={{height:'30%', padding:'10px'}}>
                                                                            No hay ninguna vista previa a este Folio, si deseas añadir una imagen Inicia Sesión
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="upload-buttons">
                                                                    <button 
                                                                        className="btn btn-primary"
                                                                        onClick={handleNavigateToLogin}
                                                                    >
                                                                        Iniciar Sesión
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div>
                                                        <ul>
                                                            <li><strong>Folio:</strong> {report.sku}</li>
                                                            <li><strong>Nombre de la imagen:</strong> {report.image_name}</li>
                                                            <li><strong>Fecha de carga:</strong> {new Date(report.upload_date).toLocaleString()}</li>
                                                            <li><strong>Distribuidor:</strong> {report.distributor}</li>
                                                        </ul>
                                                        {preview && (
                                                            <div className="mt-3">
                                                                <img src={`https://storage.googleapis.com/app-techcomp.appspot.com/${report.image_name}`} alt="Vista previa" className="img-fluid" />
                                                            </div>
                                                        )}
                                                        <a href={`https://storage.googleapis.com/app-techcomp.appspot.com/${report.image_name}`} target='_blank' style={{display:'flex', justifyContent:'center', marginTop:'20px'}} download>
                                                            <button className="btn btn-primary">Descargar Archivo</button>
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <h3>{message}</h3>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}



export function ReportDefault() {
    const [auth, setAuth] = useState(false);
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const navigate = useNavigate();
    const { setUserInfo } = useContext(UserContext);

    axios.defaults.withCredentials = true;

    useEffect(() => {
        axios.get('https://app-techcomp-16ff4d30c364.herokuapp.com')
            .then(res => {
                if (res.data.Status === "Exito") {
                    setAuth(true);
                    setUserInfo({ name: res.data.name, type: res.data.type });
                    setName(res.data.name);
                } else {
                    setAuth(false);
                    setMessage(res.data.Error);
                    navigate('/login');
                }
            })
            .catch(err => {
                console.log(err);
                setMessage("Error al registrar");
            });
    }, [navigate]);

    const handleSearch = (e) => {
        e.preventDefault();
        axios.get(`https://app-techcomp-16ff4d30c364.herokuapp.com/report/${searchQuery}`)
            .then(res => {
                if (res.data.Status === 'Exito') {
                    navigate(`/report/${searchQuery}`);
                } else {
                    setMessage(res.data.Error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Este Folio no se encuentra en la base de datos',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                    });
                }
            })
            .catch(err => {
                console.log(err);
                setMessage('Error al verificar Folio');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al verificar el Folio Front',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                });
            });
    };

    const handleInputChange = (e) => {
        const value = e.target.value.toUpperCase();
        const regex = /^[A-Z0-9]*$/;
        if (regex.test(value)) {
            setSearchQuery(value);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Caracter inválido',
                text: 'Por favor, no ingreses caracteres especiales ni correos electrónicos.', 
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            });
        }
    };

    useEffect(() => {
        const htmlElement = document.documentElement;
        if (menuCollapsed) {
            htmlElement.classList.add('layout-menu-collapsed');
        } else {
            htmlElement.classList.remove('layout-menu-collapsed');
        }
    }, [menuCollapsed]);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
        const htmlElement = document.documentElement;
        const layoutMenu = document.getElementById('layout-menu');
        if (!menuCollapsed) {
            layoutMenu.classList.add('slide-in');
            layoutMenu.classList.remove('slide-out');
            htmlElement.classList.add('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-menu-expanded');
        } else {
            layoutMenu.classList.add('slide-out');
            layoutMenu.classList.remove('slide-in');
            htmlElement.classList.remove('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-menu-expanded');
        }
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
                    <div className="app-brand demo" style={{ cursor: 'pointer' }}>
                        <a href="#" className="app-brand-link">
                            <span className="app-brand-logo demo">
                                <img src="/assets/img/favicon/favicon-techcomp.png" alt="techcomp" style={{ width: '25px' }} />
                            </span>
                            <span className="app-brand-text demo menu-text fw-semibold ms-2">Techcomp</span>
                        </a>
                    </div>

                    <div className="menu-inner-shadow"></div>

                    <ul className="menu-inner py-1">
                        <li className="menu-item active open">
                            <a href='/' className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ri-home-smile-line"></i>
                                <div data-i18n="Dashboards">Dashboard</div>
                            </a>
                        </li>
                        <li className="menu-item active open">
                            <a href='/report' className="menu-link menu-toggle">
                                <i className="menu-icon ri-file-search-line"></i>
                                <div data-i18n="Dashboards">Reportes</div>
                            </a>
                        </li>
                    </ul>
                </aside>
                <div className="layout-page">
                    <Header toggleMenu={handleToggleMenu}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row g-6">
                                <div className="col-12 col-xxl-12">
                                    <div className="card h-100">
                                        <div className="card-header">
                                            <h5 className="mb-0 text-center">Consulta de Reportes</h5>
                                        </div>
                                        <div className="card-body pt-4">
                                            <form className="d-flex" onSubmit={handleSearch}>
                                                <div className="input-group input-group-sm">
                                                    <span className="input-group-text"><i className="tf-icons ri-search-line"></i></span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Buscar..."
                                                        value={searchQuery}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </form>
                                            <br/>
                                            <p className="text-center">
                                                Aquí puedes consultar los reportes. Por favor, selecciona un folio específico para ver el detalle del reporte.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}



export default Report;
